import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import themes from '@/styles/themes';

function ThemeProvider({ children }) {
  const theme = useSelector((state) => state.store);

  useEffect(() => {
    const newTheme = themes[theme] || themes[process.env.NEXT_PUBLIC_STORE_ID];
    Object.keys(newTheme)?.forEach((key) => {
      const cssKey = `--${key}`;
      const cssValue = newTheme[key];
      document.body.style.setProperty(cssKey, cssValue);
    });
  }, [theme]);

  return children;
}

export default ThemeProvider;
